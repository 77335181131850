/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import {Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import CompanyFeature from '../../components/CompanyFeature';

import { Reports, CompanyDashBoardV2, Waiting, AdminRoute, Wellbeing, SurveyCreation, CreateSurveys, EditSurveys, LaunchSurveys, AdminMonthlyTasks, BillingTab, AddCoreValues, LaunchCompanyInitiative } from '../../components';

import People from '../People';
import Recognition from '../../components/Recognition';
import { getCompanies as getAllCompanies, setCompany, getCompany, getBillingTabStatus} from '../../redux/actions';
// import {BiometricTab} from '../../components';
import AdminDashboard from '../../components/AdminDashboard';
import { getPermissionStatus } from '../../utils/methods';
import CompleteAdminChallenge from '../ChallengeCompleted';
import CreateSurvey from '../../components/AdminSurveys/CreateSurvey';
import CategorySurveys from '../../components/AdminSurveys/SurveyLibrary/CategorySurveys';
import SurveyPreview from '../../components/AdminSurveys/SurveyLibrary/SurveyPreview';
import QuestionInsight from '../../components/AdminSurveys/SurveyInsights/QuestionInsight';
import Feedback from '../../components/AdminSurveys/SurveyInsights/Feedback';
import companyInitiativeNew from '../../components/EngagementSectionV2/CompanyInitiative/companyInitiativeNew';
import RewardsManagement from '../RewardsManagement';
import AdminEvents from '../AdminEvents';
import HrisContainer from '../HrisContainer';
import customizeDashboard from '../../components/EngagementSectionV2/CompanyInitiative/customizeDashboard';
import LaunchSurveyCustom from '../../components/AdminSurveys/CreateSurvey/launchSurvey';
import EditSurveyCustom from '../../components/AdminSurveys/CreateSurvey/editSurvey';
import CreateHealthProgram from '../../components/CoachingProgram/CreateHealthProgram';
import CoachingProgram from '../../components/CoachingProgram';
import ProgramDetail from '../../components/CoachingProgram/ProgramDetail';
import EditCoreValues from '../../components/Recognition/EditCoreValues/index';

const SurveyAnalytics = React.lazy(() => import('../../components/AdminSurveys/SurveyInsights/Analytics'));
import WellnessAdmin from '../EducationAdmin';
import createSurveyLibrary from '../../components/AdminSurveys/CreateSurvey/createSurveyLibrary';
import editSurveyLibrary from '../../components/AdminSurveys/CreateSurvey/editSurveyLibrary';
import AdminSurveys from '../AdminSurveys';

class Company extends Component {

  componentDidMount() {
    const { history, fetchAllCompanies, userCompany, setCompany, fetchCompany, userPermissions} = this.props;
    const startYear = userCompany.companyStartDate && userCompany.companyStartDate["company_start_date"];
    const year = new Date().getFullYear();
    
    const selectedCompanyJSON = localStorage.getItem('selectedCompany');
    const localCompanyInfo = JSON.parse(selectedCompanyJSON);

    if(!getPermissionStatus("Access admin portal", userPermissions) && localStorage.getItem('show_admin') === 'false') {
      history.push('/portal');
    } else if(getPermissionStatus("Show companies dropdown", userPermissions)) {
      fetchAllCompanies(year);
    } else{
      if (localCompanyInfo) {
        setCompany(localCompanyInfo.id, localCompanyInfo.name, localCompanyInfo.companyStartDate, localCompanyInfo.showBiometricStatus);
      }else {
        setCompany(userCompany.id, userCompany.name, startYear, userCompany.showBiometricStatus);
        fetchCompany();
        // fetchAllCompanies(year);
      }
    }
  }

  companyBrandingCallBack = (id) => {
    const { companyBrandingCallBack } = this.props;
    companyBrandingCallBack(id);
  }

  componentDidUpdate(prevProps){
    const{companyInfo, getBillingTabStatus, userPermissions, fetchAllCompanies, companies} = this.props;
    const year = new Date().getFullYear();
    const selectedCompanyJSON = localStorage.getItem('selectedCompany');
    const localCompanyInfo = JSON.parse(selectedCompanyJSON);
    if(localCompanyInfo){
      getBillingTabStatus(localCompanyInfo.id);
    }else if(prevProps.companyInfo !== companyInfo){
      getBillingTabStatus(companyInfo.id);
    }
    if(getPermissionStatus("Show companies dropdown", userPermissions) && !companies) {
      fetchAllCompanies(year);
    }
  }
  render() {
    const { companies, networkError, companyInfo, setCompany, role, history, isAdmin, userCompany, selectedYearInfo, userPermissions} = this.props;
    const selectedCompanyJSON = localStorage.getItem('selectedCompany');
    const localCompanyInfo = JSON.parse(selectedCompanyJSON);

    const showCompaniesDropdown = getPermissionStatus("Show companies dropdown", userPermissions);
    if(((showCompaniesDropdown && (isUndefined(companies) || isNull(companies))) || isNull(companyInfo) || isUndefined(companyInfo) || isNull(companyInfo["companyStartDate"])) && !networkError || !companyInfo) {
      return <Waiting />
    }
    const currentYear = new Date().getFullYear();
    let yearArray = [];
    for(let i = currentYear; i >= companyInfo["companyStartDate"]; i--){
      yearArray.push(i);
    }
    const yearArrayGroupSummary = yearArray.slice().reverse();
    const DownloadFileAccess = getPermissionStatus("Download report", userPermissions);
    const allowAdminTabs = localStorage.getItem('show_admin') === 'true' || getPermissionStatus("Access admin portal", userPermissions);
    return (
      <Switch>
        <AdminRoute
          path='/company/admin'
          component={AdminDashboard}
          companies={companies}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          history={history}
          role={role}
          selectedYearInfo={selectedYearInfo}
          yearList={yearArray}
          groupSummaryYearList={yearArrayGroupSummary}
        />
        <AdminRoute
          path="/company/create_company"
          component={CompanyFeature}
          companies={companies}
          parentRoute="/company/dashboard"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
        />
        <AdminRoute
          path="/company/dashboard"
          component={CompanyDashBoardV2}
          companies={companies}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          history={history}
          selectedYearInfo={selectedYearInfo}
          yearList={yearArray}
          groupSummaryYearList={yearArrayGroupSummary}
          showCompaniesDropdown={showCompaniesDropdown}
          DownloadFileAccess={DownloadFileAccess}
          companyBrandingCallBack={this.companyBrandingCallBack}
          // localCompanyId={localCompanyId}
          // localCompanyName={localCompanyName}
        />
        <AdminRoute
          path="/company/initiative"
          component={companyInitiativeNew}
          companies={companies}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          parentRoute="/company/dashboard"
          allowedRoles={allowAdminTabs}
          history={history}
          selectedYearInfo={selectedYearInfo}
          yearList={yearArray}
          groupSummaryYearList={yearArrayGroupSummary}
          showCompaniesDropdown={showCompaniesDropdown}
          DownloadFileAccess={DownloadFileAccess}
          companyBrandingCallBack={this.companyBrandingCallBack}
          role={role}
        />
        <AdminRoute
          path="/company/challenges"
          component={CompleteAdminChallenge}
          companies={companies}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          history={history}
          selectedYearInfo={selectedYearInfo}
          yearList={yearArray}
          groupSummaryYearList={yearArrayGroupSummary}
          showCompaniesDropdown={showCompaniesDropdown}
          DownloadFileAccess={DownloadFileAccess}
          companyBrandingCallBack={this.companyBrandingCallBack}
          userPermissions={userPermissions}
        />
        <AdminRoute
          path="/company/people"
          component={People}
          companies={companies}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
        />
        <AdminRoute
          // path="/company/reports"
          path="/company/health"
          component={Reports}
          companies={companies}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          selectedYearInfo={selectedYearInfo}
          yearList={yearArray}
          showCompaniesDropdown={showCompaniesDropdown}
        />
        <AdminRoute
          // path="/company/reports"
          path="/company/company-initiative"
          component={LaunchCompanyInitiative}
          companies={companies}
          parentRoute="/portal" // "/company/health"
          allowedRoles={allowAdminTabs}
          companyInfo={companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          // selectedYearInfo={selectedYearInfo}
          // yearList={yearArray}
          // showCompaniesDropdown={showCompaniesDropdown}
        />
        <AdminRoute
          path="/company/initiative-priority"
          component={customizeDashboard}
          companies={companies}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          selectedYearInfo={selectedYearInfo}
          yearList={yearArray}
          showCompaniesDropdown={showCompaniesDropdown}
        />
        <AdminRoute
          path="/company/create-health-program"
          component={CreateHealthProgram}
          // createOndemand={createOndemand}
          history={history}
          // parentRoute="/events"
          allowedRoles={allowAdminTabs}
          userPermissions={userPermissions}
        />
        <AdminRoute
          path="/company/program"
          component={CoachingProgram}
          history={history}
          // parentRoute="/events"
          allowedRoles={allowAdminTabs}
          userPermissions={userPermissions}
        />
        <AdminRoute
          path="/company/program-detail/:id"
          component={ProgramDetail}
          history={history}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          userPermissions={userPermissions}
        />
        <AdminRoute
          path="/company/recognition/addcorevalues/edit"
          component={EditCoreValues}
          parentRoute="/company/recognition"
          role={role}
          allowedRoles={allowAdminTabs}
          history={this.props.history}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
        />
        <AdminRoute
          path="/company/recognition/addcorevalues/launch"
          component={EditCoreValues}
          parentRoute="/company/recognition"
          role={role}
          allowedRoles={allowAdminTabs}
          history={this.props.history}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
        />
        <AdminRoute
          path="/company/recognition/addcorevalues"
          component={AddCoreValues}
          companies={companies}
          parentRoute="/company/recognition"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
        />
        <AdminRoute
          path="/company/recognition"
          component={Recognition}
          companies={companies}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          yearList={yearArray}
          history={this.props.history}
        />
        <AdminRoute
          path="/company/wellbeing"
          component={Wellbeing}
          companies={companies}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
        />
        {/* <AdminRoute
          path="/company/biometric"
          component={BiometricTab}
          companies={companies}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          companyInfo={companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
        /> */}

        {/* surveys routes */}
        
        <AdminRoute
          path="/company/surveys/show-all"
          component={SurveyCreation}
          companies={companies}
          parentRoute="/company/engagement"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
        />
        <AdminRoute
          path="/company/surveys/create"
          component={CreateSurveys}
          companies={companies}
          parentRoute="/company/surveys/show-all"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
        />
        <AdminRoute
          path="/company/surveys/edit"
          component={EditSurveys}
          parentRoute="/company/surveys/show-all"
          allowedRoles={allowAdminTabs}
          role={role}
          history={this.props.history}
        />
        <AdminRoute
          path="/company/surveys/launch"
          component={LaunchSurveys}
          parentRoute="/company/surveys/show-all"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          isCustomSurvey={false}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
        />
        <AdminRoute
          path="/company/surveys/launch-custom"
          component={CreateSurvey}
          parentRoute="/company/surveys/show-all"
          allowedRoles={allowAdminTabs}
          isCustomSurvey={true}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
        />
        <AdminRoute
          path="/company/surveys/launch-custom-survey"
          component={LaunchSurveyCustom}
          parentRoute="/company/surveys/show-all"
          allowedRoles={allowAdminTabs}
          isCustomSurvey={true}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
        />
        <AdminRoute
          path="/company/surveys/create-survey-library"
          component={createSurveyLibrary}
          parentRoute="/company/surveys"
          allowedRoles={allowAdminTabs}
          isCustomSurvey={true}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
        />
        <AdminRoute
          path="/company/surveys/edit-survey-library/:id"
          component={editSurveyLibrary}
          parentRoute="/company/surveys"
          allowedRoles={allowAdminTabs}
          isCustomSurvey={true}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
        />
        <AdminRoute
          path="/company/surveys/edit-custom-survey"
          component={EditSurveyCustom}
          parentRoute="/company/surveys"
          allowedRoles={allowAdminTabs}
          isCustomSurvey={true}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
        />
        <AdminRoute
          path="/company/survey-category/:id"
          component={CategorySurveys}
          companies={companies}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
          userCompany={userCompany}
          isAdmin={isAdmin}
        />
        <AdminRoute
          path="/company/survey-preview/:id"
          component={SurveyPreview}
          companies={companies}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
          userCompany={userCompany}
          isAdmin={isAdmin}
        />
        <AdminRoute
          path="/company/survey-analytics/:id"
          component={SurveyAnalytics}
          parentRoute="/company/survey-analytics/:id"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          role={role}
          history={this.props.history}
          userCompany={userCompany}
          isAdmin={isAdmin}
        />
        <AdminRoute
          path="/company/survey-insight/:id"
          component={QuestionInsight}
          companies={companies}
          parentRoute="/company/survey-insights"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
          userCompany={userCompany}
          isAdmin={isAdmin}
        />
        <AdminRoute
          path="/company/survey-feedback/:id"
          component={Feedback}
          companies={companies}
          parentRoute="/company/survey-insights"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
          userCompany={userCompany}
          isAdmin={isAdmin}
        />


        <AdminRoute
          path="/company/monthly_tasks"
          component={AdminMonthlyTasks}
          companies={companies}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
        />
        <AdminRoute
          path="/company/billing"
          component={BillingTab}
          companies={companies}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          role={role}
          history={this.props.history}
          yearList={yearArray}
          selectedYearInfo={selectedYearInfo}
        />

        <AdminRoute
          path="/company/rewards-management"
          component={RewardsManagement}
          companies={companies}
          allowedRoles={allowAdminTabs}
          parentRoute="/portal"
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          history={this.props.history}
          role={role}
          yearList={yearArray}
        />
        <AdminRoute
          path="/company/surveys"
          component={AdminSurveys}
          companies={companies}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          parentRoute="/company/surveys"
          allowedRoles={allowAdminTabs}
          history={history}
          selectedYearInfo={selectedYearInfo}
          yearList={yearArray}
          userPermissions={userPermissions}
          role={role}
        />
        <AdminRoute
          path="/company/events"
          component={AdminEvents}
          companies={companies}
          companyInfo={localCompanyInfo ? localCompanyInfo : companyInfo}
          setCompany={setCompany}
          parentRoute="/portal"
          allowedRoles={allowAdminTabs}
          history={history}
          selectedYearInfo={selectedYearInfo}
          yearList={yearArray}
          groupSummaryYearList={yearArrayGroupSummary}
          showCompaniesDropdown={showCompaniesDropdown}
          DownloadFileAccess={DownloadFileAccess}
          companyBrandingCallBack={this.companyBrandingCallBack}
          userPermissions={userPermissions}
        />
        <AdminRoute
          path="/company/hris-integration"
          component={HrisContainer}
          companies={companies}
          allowedRoles={allowAdminTabs}
          parentRoute="/portal"
          companyInfo={companyInfo}
          history={this.props.history}
          role={role}
        />
        <AdminRoute
          path="/company/wellness"
          component={WellnessAdmin}
          companies={companies}
          allowedRoles={allowAdminTabs}
          parentRoute="/portal"
          companyInfo={companyInfo}
          history={this.props.history}
          role={role}
          selectedYearInfo={selectedYearInfo}
          yearList={yearArray}
        />
      </Switch>
    )
  }
}

Company.defaultProps = {
  companyInfo: {id: 1, name: '', companyStartDate: 2016}
};
Company.propTypes = {
  isAdmin: PropTypes.string,
  history: PropTypes.object.isRequired,
  fetchAllCompanies: PropTypes.func.isRequired,
  companies: PropTypes.array,
  networkError: PropTypes.string,
  companyInfo: PropTypes.object,
  setCompany: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  userCompany: PropTypes.object.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  selectedYearInfo: PropTypes.number,
  userPermissions: PropTypes.array,
  companyBrandingCallBack: PropTypes.func,
  getBillingTabStatus: PropTypes.func
};

const mapStateToProps = (state) => ({
  companies: state.companyDashboard.companies,
  networkError: state.companyDashboard.networkError,
  companyInfo: state.companyDashboard.companyInfo,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  userPermissions: state.profileData.userPermissions
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllCompanies: (year) => dispatch(getAllCompanies(year)),
  setCompany: (id, name, companyStartDate, biometricStatus) => dispatch(setCompany(id, name, companyStartDate, biometricStatus)),
  fetchCompany: () => dispatch(getCompany()),
  getBillingTabStatus: (companyId) => dispatch(getBillingTabStatus(companyId))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Company);
